@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.list
  padding-left 0
  list-style none

  li
    position relative
    margin-bottom 10px
    text-indent 20px
    line-height remConvert(30)

    &:after
      content ''
      position absolute
      left 0
      top remConvert(14)
      margin-top remConvert(-3)
      width 6px
      height 6px
      background-color _gold
      border-radius 100%
