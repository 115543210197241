@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.catalog-card
  display flex
  flex-flow wrap
  justify-content space-between

  &__item
    width 470px
    height 240px
    margin-bottom 30px
    padding 25px 15px
    background-color #fff
    border-radius 2px
    box-shadow 0 2px 10px 0 rgba(0,0,0,.15)
    font-size 0
    transition box-shadow .5s ease

    &:hover
      box-shadow 0 2px 10px 0 rgba(0,0,0,.25)

      .catalog-card__headline
        color #000
        border-color #737373

    &--blog
      position relative
      height 410px
      padding 0

      .catalog-card
        &__content
          padding 20px
          width 100%

        &__headline--block
          padding 14px 20px

        &__headline
          text-decoration none

  &__date
  &__tags
    display inline-block
    vertical-align top

  &__date
    width 92px

  &__tags
    width calc(100% - 92px)
    a
      color #7d7565
      text-decoration none
      border-bottom 1px solid #d2cfc9
      padding-bottom 2px
      transition border-color .5s ease

      &:hover
        border-color #7d7565

  &__footer
    position absolute
    bottom 0
    left 0
    width 100%
    height 58px
    background-color #fff
    padding 18px 20px 0
    border-top 1px solid #e2e2e2

  &__image
    img
      width 100%
      height 130px

  &__avatar
  &__content
    display inline-block
    vertical-align top

  &__avatar
    width 110px

    img
      width 110px
      height 110px
      border-radius 100%

  &__content
    width calc(100% - 110px)
    padding-left 20px
    font-size 16px
    color #242528
    font-weight 300
    line-height 1.7

  span
    color #7d7565
    font-size 14px
    font-weight 300
    vertical-align middle

  .icon
    width 18px
    height 18px
    margin-right 5px
    background-color #eee
    color _gold
    border-radius 100%
    vertical-align middle

  &__text-field
    margin 8px 0

  &__headline
    color #242528
    display inline-block
    margin-bottom 6px
    font-size 22px
    border-bottom 1px solid #ccc
    line-height 1.6
    font-weight normal
    transition border-color .5s ease, color .5s ease
