@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.numeric-list
  margin-top remConvert(25)

  &__item
    margin-bottom 25px
    font-style italic
    vertical-align middle

    &:last-child
      .numeric-list__item-num
        &:after
          display none

    &-num
      position relative
      display inline-block
      width remConvert(50)
      height remConvert(50)
      margin-right remConvert(10)
      vertical-align middle
      font-style normal
      text-align center
      color _gold
      line-height remConvert(48)
      fontConvert(22)
      border 2px solid _gold
      border-radius 100%

      &:after
        content ''
        position absolute
        bottom -20px
        left 50%
        margin-left -4px
        width 9px
        height 15px
        background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpENTVGM0JFRkU5NzIxMUU1OEFEN0QxNjE3REFGOTNFMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENTVGM0JGMEU5NzIxMUU1OEFEN0QxNjE3REFGOTNFMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ1NUYzQkVERTk3MjExRTU4QUQ3RDE2MTdEQUY5M0UwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ1NUYzQkVFRTk3MjExRTU4QUQ3RDE2MTdEQUY5M0UwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+fp1rKAAAAINJREFUeNpi+v//PwMyPrOm9D+6GBMDEWDIKmIEehld7D9IHN2kRUCshcMQkPgikKKDQLwUiNnQFLBBxQ+CFM0F4vtA3IKmqAUqPpcFKpAKxBeBeDuU7wjEUUCsj+y7t0CcBMQLoPwFUP5b9CDYBcTrgfgTlN6FK5z6gfgJlIYDgAADALjIMqy9NoaPAAAAAElFTkSuQmCC')

    &-text
      display inline-block
      width calc(100% - 4.125rem)
      vertical-align middle

    a
      color inherit
      text-decoration none
      border-bottom 1px dotted _milk

      &:hover
        border none

    span
      color _milk


