@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.course-tabs
  background-color _main-gray
  border-bottom 1px solid #4b4d53

  &__header
    position relative
    border-bottom 1px solid #4b4d53
    font-size 0
    display flex

    .container
      position relative

      &:after
        content ''
        position absolute
        top 0
        bottom 0
        right 0
        width 120px
        height 100%
        background-image linear-gradient(to right, rgba(36,37,40, .2), rgba(36,37,40, 1))

    &-nav
      position absolute
      right 0
      top 0
      z-index 20
      width 80px
      height 40px
      font-size 0
      overflow hidden
      border 1px solid #333439
      border-radius 2px

      &-prev
      &-next
        position relative
        display inline-block
        width 50%
        height 100%
        cursor pointer

        &:hover
          background-color rgba(255,255,255,.05)

        svg
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          stroke _gold
          width 50%
          height 50%

      &-prev
        border-right 1px solid #333439

    .owl-carousel .owl-wrapper-outer
      bottom -2px

    .owl-item
      display inline-block
      vertical-align bottom
      float none

    &-item
      position relative
      z-index 3
      display inline-block
      //width (100 / 3)%
      padding-right remConvert(90)
      padding-bottom remConvert(40)
      //margin-bottom -2px
      color #86888e
      fontConvert(22)
      vertical-align bottom
      transition color .5s ease, border-color .5s ease
      border-bottom 2px solid transparent
      cursor pointer

      &.active
      &:hover
        color _gold
        border-color _gold


  &__body
    font-size 0

  &__main
  &__aside
    font-size initial
    display inline-block
    vertical-align top


  &__main
    width 65%
    border-right 1px solid #4b4d53

    &-header
      color _milk

      .h2
        color #fff
        fontConvert(25)
        line-height 1.3
        padding-top 30px

      &__banner
        position relative
        height 160px
        background-size cover
        background-position center
        color #fff

        &:after
          content ''
          position absolute
          z-index 1
          top 0
          left 0
          display block
          height 100%
          width 100%
          background-image linear-gradient(to bottom, rgba(0,0,0,.4), rgba(0,0,0,0), rgba(0,0,0,.8))

        &-star
          position absolute
          z-index 2
          bottom 20px
          left 10px
          fontConvert(22)

          i
          span
            vertical-align middle

          i
            display inline-block
            width remConvert(26)
            height remConvert(26)
            margin-right remConvert(15)
            border 2px solid _gold
            border-radius 100%

          span
            display inline-block

  &__aside
    position relative
    width 35%
    color #fff

    &-wrapper
      position absolute
      top 0
      left 0
      transition top ease 0.1s

    .headline
      margin-bottom remConvert(20)

      &__other
        color _gold !important
        font-weight _light

      &__text
        color _milk

    &-header
    &-info
      padding remConvert(40) 0
      padding-left remConvert(22)

    &-header
      border-bottom 1px solid #4b4d53

    //&-info


