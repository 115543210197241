@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.info
  &__item
    display block
    font-weight _light
    color _milk
    text-decoration none
    line-height 2

    span
      padding-bottom 2px
      border-bottom 1px solid #585858

    &:hover
      span
        border none
