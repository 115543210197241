@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.course-table
  table
    width 100%
    margin-bottom remConvert(10)
    fontConvert(16)
    text-align center
    color _milk

  tr
    border 1px solid #4b4d53
    height remConvert(70)

    .button
      width 100%

    &:not(.course-table__thead):not(.course-table__tfooter)
      &:hover
        background-color _table-hover

      .button
        border-color _button-hover
        color _gold !important
        background-color transparent

        &:hover
          color _main-gray !important
          background-color #c8a252

  a:not(.button)
    &:hover
      border-bottom 1px solid
      padding-bottom 1px

  td
    padding 0 remConvert(15)

  td:first-of-type
    color #fff

  &__thead
  &__tfooter
    background-color _schedule-footer
    color #fff

  &__tfooter
    .button
      color _main-gray !important

      &:hover
        color _gold !important

  &__thead
    height remConvert(60)
    text-transform capitalize

    td
      border 1px solid #4b4d53
