@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

_price-gb = #2f3033;

.prices
  width 1000px
  margin auto
  text-align center

  @media (min-width 1650px)
    width 1280px

  &__item
    position relative
    padding 0 remConvert(20)
    padding-bottom remConvert(160)
    height remConvert(540)
    color _milk
    border 2px solid _gold
    border-radius 1px

    .button
      color _main-gray

      &:hover
        background-color #c8a252
        color _main-gray

    &-header
      color _milk
      text-align center
      fontConvert(22)
      height remConvert(160)
      margin-bottom remConvert(30)

      &-wrapper
        position relative
        padding remConvert(35) 0 remConvert(30)

        &:after
          content ''
          position absolute
          bottom 10px
          left 0
          right 0
          width 42px
          height 2px
          margin auto
          background-color _gold

      &-type
        color #fff



    &-list
      &-prices
        position relative
        overflow hidden
        display block
        width 100%
        margin-bottom remConvert(10)

        &:before
          content ''
          position absolute
          bottom remConvert(9)
          left 0
          width 100%
          border-bottom 1px dashed #515151

        &:after
          content ''
          width 100%
          display table-cell
          overflow hidden
          clear both

        &__name
        &__wrapper
          position relative
          z-index 1
          background _price-gb

        &__name
          float left

        &__wrapper
          float right
          padding-left 2px

        &__price
          display inline-block
          fontConvert(22)

          @media (min-width 1650px)
            fontConvert(18)

          border-right 1px solid _milk
          padding-right remConvert(5)
          line-height 1

          &:first-child
            color _gold

          &:last-child
            border none


          &.eur
            &:after
              content '€'
          &.rur
            &:after
              content 'P'

        &.outline
          .prices__item-list-prices__name
            border-bottom 1px dotted




    &-footer
      position absolute
      left 0
      bottom 0
      width 100%
      text-align center
      heihgt remConvert(160)
      padding remConvert(35) remConvert(20)

      &-colors
        margin-bottom remConvert(30)

        &__untill
        &__after
          position relative
          display inline-block
          padding-left 15px
          margin-left 5px

          &:after
            content ''
            position absolute
            left 0
            top 50%
            margin-top -5px
            width 10px
            height 10px

        &__untill
          &:after
            background-color _gold

        &__after
          &:after
            background-color #b4b4b7


  &__container
    //position relative
    z-index 10
    display inline-block
    width 300px
    vertical-align top
    margin 0 remConvert(10) remConvert(30)

    @media (min-width 1650px)
      width 350px

    //&:hover
    //  transform scale(1.04)

  &__wrapper
    background-color _price-gb
    padding 10px
    border 1px solid #484a50
    border-radius 1px
    box-shadow 5px 5px 54px 5px rgba(0,0,0,.35)
    transition transform ease .3s


    &.pack
      width 98%

    &-helper-1
    &-helper-2
      position absolute
      width 98%
      height 100%
      background-color _price-gb
      border 1px solid #484a50
      border-radius 1px

    &-helper-1
      z-index -2
      left 10px
      top 10px

    &-helper-2
      z-index -1
      left 5px
      top 5px

    &:hover
      background-color #fff
      transform scale(1.04)

      .prices__item
        color #242528

        &-header
          color #242528

          &-wrapper
            &:after
              background-color #c8a252

        &-header-type
           color #242528

        &-list
          &-prices
            &__price
              color #b4b4b7

              &:first-child
                color #c8a252

            &__wrapper
            &__name
              background #fff

        &-footer
          &-colors
            &__untill
              &:after
                background-color #c8a252

