@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.profile

  .banner
  .banner__item
    height 80vh !important

  &__content
    padding 75px 0
    background-color #fff
    font-size 0

    &-main
    &-aside
      display inline-block
      font-size 16px
      vertical-align top

    &-main
      width 60%
      padding-right 25px

    &-aside
      width 40%

    .content-field
      line-height 2

