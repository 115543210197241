@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.tags
  margin-bottom 30px
  font-weight 300
  color #86888e
  font-size 0

  &__headline
  &__content
    display inline-block
    font-size remConvert(16)
    vertical-align top

  &__headline
    width 50px

  &__content
    width calc(100% - 50px)

  &__tag
    position relative
    display inline-block
    line-height remConvert(30)
    margin-right 5px
    margin-bottom 5px
    padding-right 18px
    padding-left 10px
    background-color #e2e2e2
    border-radius 2px
    text-decoration none
    color #86888e

    &:after
      content ''
      position absolute
      top 50%
      right 9px
      margin-top -1px
      width 2px
      height 2px
      border-radius 100%
      background-color #86888e

    &.active
      color #242528
      background-color _gold


