@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.nav-table
  width 100%

  &__header
    font-size 0
    border-bottom 1px solid _milk

    &-item
      display inline-block
      width (100 / 3)%
      fontConvert(18)
      font-weight _regular

  &__wrapper
    font-size 0

    ul
      display inline-block
      padding-left 0
      width (100 / 3)%
      fontConvert(16)
      font-weight _light
      list-style none
      vertical-align top

      a
        text-decoration none
        white-space nowrap

        &:hover
          text-decoration underline