@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.blog
  font-size 0
  margin-bottom 40px

  .headline.h2
    padding-bottom 15px
    margin-bottom 15px

  &__date
  &__tags
    display inline-block
    vertical-align top
    margin-bottom 10px

  &__date
    width 92px

  &__tags
    width calc(100% - 92px)

  &__other-post
  .content-field
    display inline-block
    vertical-align top


  .content-field
    width 70%
    font-size 16px
    padding-right 30px

  &__other-posts
    width 30%

  a
    color #7d7565
    text-decoration none
    border-bottom 1px solid #d2cfc9
    padding-bottom 2px
    transition border-color .5s ease

    &:hover
      border-color #7d7565

  span
    color #7d7565
    font-size 14px
    font-weight 300
    vertical-align middle

  .icon
    width 18px
    height 18px
    margin-right 5px
    background-color #eee
    color _gold
    border-radius 100%
    vertical-align middle

  .h4
    display inline-block
    text-decoration none
    color #242528
    white-space pre
    margin-bottom 5px

  &__image
    margin-bottom 30px

    img
      width 100%
      height 268px


.content-field
  font-weight 300
  color #242528

  h2
  h3
  h4
    color inherit
    margin-bottom 10px
    font-weight normal

  h2
    fontConvert(_h2)

  h3
    fontConvert(_h3)

  h4
    fontConvert(_h4)

  img
    display block
    width 100%

  blockquote
    position relative
    padding 20px 0
    margin 40px 0
    color _gold
    fontConvert(25)
    font-style italic
    font-weight 300
    border-top 1px solid _gold

    &:after
      content ''
      position absolute
      bottom 0
      left 0
      width 50%
      border-bottom 1px solid _gold
