@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

footer
  padding-top remConvert(50)
  padding-bottom remConvert(60)

.footer
  display flex
  flex-flow row wrap
  justify-content space-between

  &__nav
    flex-basis 40%
    text-align left

  &__info
    flex-basis 50%
    text-align right

    .shared
    .subscribe
      display inline-block
      vertical-align middle
      line-height 0

    .subscribe
      margin-right remConvert(20)

  &__publish
  &__address
    margin-bottom remConvert(40)

  &__address
    line-height 1.4

    span
      fontConvert(18)

  &__copy
    &-top
      text-transform uppercase
      fontConvert(20)

    &-icon
      display inline-block
      width remConvert(38)
      height remConvert(38)

      svg
        width 100%
        height 100%
        fill currentColor

  &__bottom
    fontConvert(18)
