// create @font-face
// it needs that file directory name equal font name
_font-face(_fontName, _fileName, _fontWeight = normal, _fontStyle = normal)
  _url = '../fonts/' + _fileName + '/' + _fileName;
  @font-face
    font-family _fontName
    font-style _fontStyle
    font-weight _fontWeight
    src url(_url + '.eot')
    src url(_url + '.eot?#iefix') format('embedded-opentype'),
url(_url + '.woff2') format('woff2'),
url(_url + '.woff') format('woff'),
url(_url + '.svg#' + _fontName + _fontStyle + _fontWeight) format('svg')

remConvert(size)
  (size / _desktopFontSize)rem

fontConvert(size)
  font-size remConvert(size)

// placeholders
placeholder()
  _selectors = ('::-webkit-input-placeholder' '::-moz-placeholder' ':-moz-placeholder' ':-ms-input-placeholder');
  for item in _selectors
    &{item}
      {block}

// Clear the float on any element.
clearfix()
  &:after
    display table
    clear both
    content ''

// Fix no working `-webkit-text-size-adjust: 100%;` in Android.
fix-text-size-adjust()
  background-image linear-gradient(transparent, transparent)

// Set element size
size($width, $height = $width)
  width $width
  height $height

// Set margin on left & right
margin-x($margin)
  margin-left $margin
  margin-right $margin

// Set margin on top & bottom
margin-y($margin)
  margin-top $margin
  margin-bottom $margin

// Set pading on left & right
padding-x($padding)
  padding-left $padding
  padding-right $padding

// Set padding on top & bottom
padding-y($padding)
  padding-top $padding
  padding-bottom $padding

