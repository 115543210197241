// 1. Address `appearance` set to `searchfield` in Safari and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari and Chrome

input[type="search"]
  -webkit-appearance textfield   // 1
  box-sizing content-box // 2


// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).

input[type="search"]::-webkit-search-cancel-button
input[type="search"]::-webkit-search-decoration
  -webkit-appearance none
