@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.button
  display inline-block
  padding-left remConvert(20)
  padding-right remConvert(20)
  //padding-right remConvert(36)
  font-weight _light
  fontConvert(18)
  line-height remConvert(38)
  text-align center
  color _gold
  border 1px solid _gold
  border-radius 2px
  text-decoration none
  transition background-color .5s ease, color .5s ease

  &__icon
    position relative
    display inline-block
    margin-left remConvert(5)
    width remConvert(14)
    height remConvert(14)
    vertical-align middle

    svg
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      margin auto
      width 100%
      height 100%
      stroke currentColor

  &:hover
    background-color _gold
    color _button-hover

  &.alternate
    border-radius 1px
    background-color _gold
    color _main-gray

    .button__icon
      display none

    &:hover
      background-color _main-gray
      color _gold

  &.big
    padding-left remConvert(25)
    padding-right remConvert(25)
    line-height remConvert(43)


  &.select
    position relative
    color _milk
    cursor pointer
    transition border-color .5s ease

    &:hover
      background-color transparent
      color #fff
      border-color #826a38

      .button
        &__icon:not(.location)
          transform rotateX(180deg)
          transition transform .5s ease

        &__select
          top 100%
          height 200%
          opacity 1
          border-color #826a38
          transition height .3s ease, opacity .5s ease

    .button
      &__icon
        &.location
          margin-left 0
          margin-right remConvert(5)

      &__select
        position absolute
        top 0
        left -1px
        height 0
        width calc(100% + 2px)
        opacity 0
        overflow hidden
        border 1px solid _gold
        border-top none
        text-align center
        border-radius 0 0 2px 2px
        background-color rgba(255,255,255, .1)

        &-item
          display block
          color _milk
          text-decoration none

          &:hover
          &.active
            color #fff
