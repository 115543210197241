@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.download-other
  font-size remConvert(16)
  color _gold
  text-decoration none

  &:hover
    span
      border-color transparent

  span
    margin-left remConvert(5)
    padding-bottom remConvert(4)
    border-bottom 1px dashed #d2cfc9
    transition border-color .5s ease

  span
  svg
    vertical-align middle

