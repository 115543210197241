// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type `input` and others.

button,
html input[type="button"],   // 1
input[type="reset"],
input[type="submit"]
  -webkit-appearance: button //2
  cursor pointer             //3
