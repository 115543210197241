@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.headline
  position relative
  padding-bottom remConvert(30)
  margin-bottom remConvert(50)
  text-align center

  &:after
    content ''
    position absolute
    bottom 0
    left 50%
    width 140px
    height 4px
    margin-left -70px
    background-color _milk
    border-radius 4px

  &.left
    text-align left

    &:after
      left 0
      margin 0

  &.right
    text-align right

    &:after
      left auto
      right 0
      margin 0

  &__other
    color _gold
    font-weight 300

  &__text
    fontConvert(16)
    color inherit


/**
  params
*/

  //font size params
  &.h1
    > .headline
      &__main
        font-weight _light
        fontConvert(_h1)
        line-height 1.4

      &__other
        fontConvert(30)
        line-height remConvert(41)

  &.h2
    > .headline
      &__main
        font-weight _regular
        fontConvert(_h2)
        line-height 1.4

      &__other
        fontConvert(24)
        line-height remConvert(41)

    &.headline
      &:after
        height 2px

  // line params
  &.alternate
    padding-bottom 0

    &:after
      display none

    > .headline
      &__other
        fontConvert(24)
        line-height remConvert(41)
        color inherit

  &.same
    margin-bottom remConvert(20)
    padding-bottom remConvert(20)

  // font-weight params
  &.format2
    > .headline
      &__main
        font-weight _regular

  //color params
  &.gold
    &.headline
      &:after
        background-color _gold

  &.black
    .headline
      &__other
        color #242528
