@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.payments
  font-size 0

  &__item
    display inline-block
    width (100 / 5)%
    vertical-align middle
    text-align center


    img
      line-height 0
      max-width 70%
