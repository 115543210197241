@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.scroll-down
  position absolute
  left 0
  right 0
  bottom remConvert(80)
  z-index 3
  margin auto
  width remConvert(20)
  height remConvert(55)
  cursor pointer

  &.static
    position static

  svg
    width 100%
    height 100%
