@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.navigation
  position absolute
  top 45px
  left 0
  right 0
  z-index 50
  display flex
  width 100%
  min-width 1024px
  padding 0 remConvert(104)
  justify-content flex-end
  align-items center
  height 70px

  //&__logo
  //&__buttons
  //  vertical-align middle

  &__logo
    position absolute
    left remConvert(57)
    top 50%
    margin-top -25px

  &__links
    margin-right 17px

    ul
      list-style none

      li
        display inline-block

        &.active
          a
            position relative
            color #fff

            &:after
              content ''
              position absolute
              bottom -10px
              left 0
              width 36px
              border-bottom 2px solid _gold

      a
        display inline-block
        margin 0 17px
        color #d9d8d6
        font-size 18px
        font-weight 400
        text-decoration none
        transition color .5s ease

        &:hover
          color #fff

.grey-nav
  padding-top 115px

  .navigation
    background-color #2f3033
    height 80px

