@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.icons-table
  margin-bottom 10px

  table
    width 100%

  tr
    height remConvert(190)

  td
    width 25%
    text-align center
    border 1px solid _schedule-line
    vertical-align middle

    img
      max-width 70%
      max-height 70%

  &.alternate
    td
      border-color _border-color

    tr
      height remConvert(110)

  &.double
    font-size 0

    .td
      position relative
      display inline-block
      width 50%
      vertical-align middle
      text-align center
      border-bottom 1px solid _schedule-line
      border-left 1px solid _schedule-line
      height remConvert(190)

      img
        position absolute
        top 0
        left 0
        bottom 0
        right 0
        margin auto
        max-width 70%
        max-height 70%

      &:nth-child(2n)
        border-right 1px solid _schedule-line

      &:nth-child(1)
      &:nth-child(2)
        border-top 1px solid _schedule-line

