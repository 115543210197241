@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.courses
  display flex

  &.light
    background-color #fff
    color _main-gray
    border-bottom 1px solid #e5e5e6

    .courses
      &__description
        .headline__main
          span:last-of-type
            color #000

  &.dark
    background-color _main-gray
    color _milk

    .courses
      &__description
        .headline__main
          span:last-of-type
            color #fff

  &__banner
    flex-basis 30%
    height 100%

    @media (min-width 1280px)
      flex-basis 40%

    @media (min-width 1650px)
      flex-basis 50%

    &-image
      height inherit
      background-size cover
      background-position center top

  &__slider
    align-self center
    padding-left remConvert(100)
    padding-right remConvert(150)
    flex-basis 70%

    @media (min-width 1280px)
      flex-basis 60%

    @media (min-width 1650px)
      flex-basis 50%


  &__description
    &-text
      margin-bottom remConvert(50)
      height 200px
