@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.banner
  position relative
  height 100vh

  a:not(.button):not(.tabs__item)
    color _gold
    border-bottom 1px solid #61553c
    text-decoration none

    &:hover
      border none

  &:before
  &:after
    content ''
    position absolute
    z-index 1
    left 0
    right 0
    height 150px
    width 100%

  &:before
    top 0
    background-image linear-gradient(rgba(0,0,0,1) 20%, rgba(0,0,0,.12) 70%, rgba(0,0,0,0))

  &:after
    display none // TODO временно убран, пока не поставлю нормальное лого Изотоп
    bottom 0
    background-image linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.88))

  &__item
    position relative
    height 100vh
    display flex
    justify-content center
    color #fff
    text-align center
    background-size cover
    background-position center

    &:after
      content ''
      position absolute
      z-index 1
      top 0
      left 0
      bottom 0
      right 0
      width 100%
      height 100%
      background-color rgba(0,0,0,.7)

    &-container
      position relative
      z-index 2
      display flex
      width _1024
      flex-basis _1024
      flex-direction column
      align-self center

      @media (min-width 1280px)
        width _1280

    .headline
      &__main
        text-transform uppercase

      &__other
        font-weight _light

    &-text
      fontConvert(18)
      margin-bottom remConvert(20)

  /**
    params
  */

  &.inner
    height 80vh
    //&:after
    //  height 90%
    //  background-image linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 40%, rgba(0,0,0,0.88) 60%)

    .headline
      &__main
        font-weight _regular
        fontConvert(54)

    .banner
      &__item
        height 80vh

        &-text
          fontConvert(22)
          font-style italic
          margin-bottom remConvert(30)

        &-container
          align-self flex-end
          padding-bottom remConvert(150)

    .tabs
      margin-bottom remConvert(20)

  &.alternate
  &.inner_float_left
    height 450px

    .banner
      &__item
        height 450px
        background-position center top

        &-container
          align-self flex-end
          margin-bottom remConvert(40)

  &.inner_float_left
    height 80vh



/*params end*/


.pagination
  position absolute
  display inline-block
  left 0
  right 0
  bottom remConvert(150)
  margin auto
  z-index: 1
  text-align center


  &__item
    display: inline-block
    width remConvert(14)
    height remConvert(14)
    margin-right remConvert(10)
    border 1px solid _milk
    border-radius 100%
    transition background-color ease .5s
    cursor pointer

    &.active
    &:hover
      background-color _milk
