@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.about

  &__wrapper
    align-self center
    width 70%
    margin auto

    @media (min-width 1280px)
      width 60%

    @media (min-width 1650px)
      width 50%

  &__text
    margin-bottom remConvert(50)
    height 300px

.about-inner
  .headline
    width 60%

    &__main
      line-height 1.1 !important

  &__text
    margin-bottom remConvert(30px)

  &__link
    color #7d7565 !important

    &:hover
      padding-bottom 1px
      border-bottom 1px solid #7d7565
