@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.card
  width 1000px
  padding 20px
  margin auto
  background-image url('../images/card.jpg')
  background-size cover
  background-position center
  border-radius 4px
  color #fff
  text-align center
  box-shadow 0 5px 54px 0 rgba(0,0,0,.45)

  a:not(.button)
    color _gold
    text-decoration none

    &:hover
      border-bottom 1px dotted _gold

  &__wrapper
    border 4px solid _gold
    padding 60px 40px 20px
    border-radius 4px

  &__header
    width 70%
    margin auto

    .h2
      color _gold

  &__main
    margin-bottom 40px

  &__logo
    border none !important

  &__footer
    width 90%
    margin auto
    color _gold
    font-size 16px

    a:not(.card__logo):hover
      border-bottom 1px solid _gold !important

  .headline
    display inline-block

    &__main
      font-size 22px

    &:after
      left 0
      width 100%
      height 2px
      margin 0

.steps
  display flex
  flex-flow row wrap
  justify-content space-between

  &__step
    position relative
    width 260px
    text-align center

    &:first-of-type
    &:last-of-type
      &:after
        content ''
        position absolute
        top 19px
        width 262px
        height 9px
        background-image url('../images/card-line.png')
        background-size 100% 9px


    &:first-of-type
      &:after
        left 152px
    &:last-of-type
      &:after
        right 152px

    &-order
      width 47px
      height 47px
      margin auto
      margin-bottom 10px
      font-size 20px
      color _gold
      text-align center
      line-height 45px
      border 2px solid _gold
      border-radius 100%
