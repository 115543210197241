@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.schedule
  width 45%
  min-width 900px
  margin auto
  margin-bottom remConvert(90)
  color _milk
  box-shadow 0 5px 5px 0 rgba(0,0,0,.15)

  .h4
    color #fff
    margin-bottom remConvert(20)

  &__body
    padding remConvert(40) remConvert(80) remConvert(20)
    width 100%
    background-color _main-gray
    border-radius 2px 2px 0 0

    .td
        &:nth-of-type(2)
        &:nth-of-type(3)
          width 30%
        &:nth-of-type(1)
        &:nth-of-type(4)
          width 20%

    &.info
      .td
        width (100 / 3)%

    .tr
      border-bottom 1px solid _schedule-line
      transition background-color .5s ease
      font-size 0

      &:last-of-type
        border none

      &:hover
        background-color _schedule-footer
        box-shadow 0 2px 2px 0 rgba(0,0,0,.15)

      .td
        display inline-block
        //line-height remConvert(60)
        padding remConvert(10)
        vertical-align middle
        fontConvert(16)

        &:first-of-type
          padding-left remConvert(30)
          color #fff

        &:last-of-type
          text-align right

        .button
          border none
          color rgba(0,0,0,0)

          &__icon
            width remConvert(20)
            height remConvert(20)

          svg
            stroke _gold

          &:hover
            color _button-hover

            svg
              stroke _main-gray

  &__footer
    padding remConvert(25) remConvert(105) remConvert(40)
    background-color _schedule-footer
    border-top 1px solid _schedule-line
    border-radius 0 0 2px 2px

.separator
  padding 0 remConvert(10)
