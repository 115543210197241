/***
	need to require to every file

	@import 'helpers/variables'
	@import 'helpers/mixins'
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900&subset=latin,cyrillic");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700&subset=latin,cyrillic");
@import url("../fonts/font-awesome.min.css");
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  padding: 0;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  font-size: 12px;
}
@media (min-width: 1280px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1650px) {
  html {
    font-size: 16px;
  }
}
body {
  min-width: 1024px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.875rem;
}
.default-block {
  min-height: 550px;
  height: 40vw;
  width: 100%;
}
.block-offset {
  padding: 6.25rem 0;
}
.element-offset {
  width: 80%;
  min-width: 900px;
  align-self: center;
  margin: auto;
}
.h1 {
  font-size: 3.75rem;
}
.h2 {
  font-size: 2.5rem;
}
.h3 {
  font-size: 2rem;
}
.h4 {
  font-size: 1.5625rem;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-italic {
  font-style: italic;
}
.text-light {
  font-weight: 300;
}
.container {
  width: 55%;
  min-width: 900px;
  margin: auto;
}
.container.wide {
  width: 100%;
}
.container--fixed {
  width: 1000px;
  padding: 15px;
}
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: underline !important;
}
.bg {
  position: relative;
  background-size: cover;
  background-position: center;
}
.bg .container {
  position: relative;
  z-index: 2;
}
.bg:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
}
.grey {
  background-color: #242528 !important;
  color: #fff !important;
}
.grey a {
  color: #b4b4b7;
  border-bottom: 1px solid #585858;
  transition: border-color 0.5s ease;
  margin-right: 15px;
  padding-bottom: 1px;
  text-decoration: none;
}
.grey a:hover {
  border-color: #b4b4b7;
}
.w60 {
  max-width: 60%;
}
#big-video-vid_html5_api {
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}
.B_crumbBox {
  padding: 0;
  list-style: none;
  color: #c2aa77;
  font-weight: 300;
}
.B_crumbBox li {
  display: inline-block;
}
.B_crumbBox li a {
  color: #c2aa77;
  font-weight: 300;
}
.B_crumbBox li a:hover {
  text-decoration: none;
}
.about__wrapper {
  align-self: center;
  width: 70%;
  margin: auto;
}
@media (min-width: 1280px) {
  .about__wrapper {
    width: 60%;
  }
}
@media (min-width: 1650px) {
  .about__wrapper {
    width: 50%;
  }
}
.about__text {
  margin-bottom: 3.125rem;
  height: 300px;
}
.about-inner .headline {
  width: 60%;
}
.about-inner .headline__main {
  line-height: 1.1 !important;
}
.about-inner__text {
  margin-bottom: 1.875rem;
}
.about-inner__link {
  color: #7d7565 !important;
}
.about-inner__link:hover {
  padding-bottom: 1px;
  border-bottom: 1px solid #7d7565;
}
.anchor-list {
  margin-bottom: 30px;
}
.anchor-list__chars {
  font-size: 0;
}
.anchor-list__chars a {
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 300;
  color: #cecfd1;
  text-decoration: none;
  text-align: center;
  transition: color 0.5s ease;
}
.anchor-list__chars a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.anchor-list__chars a:hover {
  color: #a0a2a6;
}
.anchor-list__chars a.active {
  color: #c2aa77;
}
.anchor-list__chars a.active:after {
  bottom: -1px;
  border-bottom: 2px solid #c2aa77;
}
.anchor-list__chars--en a {
  width: 4.166666666666667%;
}
.anchor-list__chars--ru a {
  width: 33px;
}
.banner {
  position: relative;
  height: 100vh;
/**
    params
  */
}
.banner a:not(.button):not(.tabs__item) {
  color: #c2aa77;
  border-bottom: 1px solid #61553c;
  text-decoration: none;
}
.banner a:not(.button):not(.tabs__item):hover {
  border: none;
}
.banner:before,
.banner:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  height: 150px;
  width: 100%;
}
.banner:before {
  top: 0;
  background-image: linear-gradient(#000 20%, rgba(0,0,0,0.12) 70%, rgba(0,0,0,0));
}
.banner:after {
  display: none;
  bottom: 0;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.88));
}
.banner__item {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
}
.banner__item:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}
.banner__item-container {
  position: relative;
  z-index: 2;
  display: flex;
  width: 55%;
  flex-basis: 55%;
  flex-direction: column;
  align-self: center;
}
@media (min-width: 1280px) {
  .banner__item-container {
    width: 45%;
  }
}
.banner__item .headline__main {
  text-transform: uppercase;
}
.banner__item .headline__other {
  font-weight: 300;
}
.banner__item-text {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.banner.inner {
  height: 80vh;
}
.banner.inner .headline__main {
  font-weight: 400;
  font-size: 3.375rem;
}
.banner.inner .banner__item {
  height: 80vh;
}
.banner.inner .banner__item-text {
  font-size: 1.375rem;
  font-style: italic;
  margin-bottom: 1.875rem;
}
.banner.inner .banner__item-container {
  align-self: flex-end;
  padding-bottom: 9.375rem;
}
.banner.inner .tabs {
  margin-bottom: 1.25rem;
}
.banner.alternate,
.banner.inner_float_left {
  height: 450px;
}
.banner.alternate .banner__item,
.banner.inner_float_left .banner__item {
  height: 450px;
  background-position: center top;
}
.banner.alternate .banner__item-container,
.banner.inner_float_left .banner__item-container {
  align-self: flex-end;
  margin-bottom: 2.5rem;
}
.banner.inner_float_left {
  height: 80vh;
}
.pagination {
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  bottom: 9.375rem;
  margin: auto;
  z-index: 1;
  text-align: center;
}
.pagination__item {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.625rem;
  border: 1px solid #b4b4b7;
  border-radius: 100%;
  transition: background-color ease 0.5s;
  cursor: pointer;
}
.pagination__item.active,
.pagination__item:hover {
  background-color: #b4b4b7;
}
.blog {
  font-size: 0;
  margin-bottom: 40px;
}
.blog .headline.h2 {
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.blog__date,
.blog__tags {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
}
.blog__date {
  width: 92px;
}
.blog__tags {
  width: calc(100% - 92px);
}
.blog__other-post,
.blog .content-field {
  display: inline-block;
  vertical-align: top;
}
.blog .content-field {
  width: 70%;
  font-size: 16px;
  padding-right: 30px;
}
.blog__other-posts {
  width: 30%;
}
.blog a {
  color: #7d7565;
  text-decoration: none;
  border-bottom: 1px solid #d2cfc9;
  padding-bottom: 2px;
  transition: border-color 0.5s ease;
}
.blog a:hover {
  border-color: #7d7565;
}
.blog span {
  color: #7d7565;
  font-size: 14px;
  font-weight: 300;
  vertical-align: middle;
}
.blog .icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background-color: #eee;
  color: #c2aa77;
  border-radius: 100%;
  vertical-align: middle;
}
.blog .h4 {
  display: inline-block;
  text-decoration: none;
  color: #242528;
  white-space: pre;
  margin-bottom: 5px;
}
.blog__image {
  margin-bottom: 30px;
}
.blog__image img {
  width: 100%;
  height: 268px;
}
.content-field {
  font-weight: 300;
  color: #242528;
}
.content-field h2,
.content-field h3,
.content-field h4 {
  color: inherit;
  margin-bottom: 10px;
  font-weight: normal;
}
.content-field h2 {
  font-size: 2.5rem;
}
.content-field h3 {
  font-size: 2rem;
}
.content-field h4 {
  font-size: 1.5625rem;
}
.content-field img {
  display: block;
  width: 100%;
}
.content-field blockquote {
  position: relative;
  padding: 20px 0;
  margin: 40px 0;
  color: #c2aa77;
  font-size: 1.5625rem;
  font-style: italic;
  font-weight: 300;
  border-top: 1px solid #c2aa77;
}
.content-field blockquote:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  border-bottom: 1px solid #c2aa77;
}
.breadcrumbs {
  color: #b4b4b7;
}
.breadcrumbs a {
  color: inherit;
  text-decoration: underline;
}
.breadcrumbs a:hover {
  color: #fff;
}
.button {
  display: inline-block;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 2.375rem;
  text-align: center;
  color: #c2aa77;
  border: 1px solid #c2aa77;
  border-radius: 2px;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.button__icon {
  position: relative;
  display: inline-block;
  margin-left: 0.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: middle;
}
.button__icon svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  stroke: currentColor;
}
.button:hover {
  background-color: #c2aa77;
  color: #404040;
}
.button.alternate {
  border-radius: 1px;
  background-color: #c2aa77;
  color: #242528;
}
.button.alternate .button__icon {
  display: none;
}
.button.alternate:hover {
  background-color: #242528;
  color: #c2aa77;
}
.button.big {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  line-height: 2.6875rem;
}
.button.select {
  position: relative;
  color: #b4b4b7;
  cursor: pointer;
  transition: border-color 0.5s ease;
}
.button.select:hover {
  background-color: transparent;
  color: #fff;
  border-color: #826a38;
}
.button.select:hover .button__icon:not(.location) {
  transform: rotateX(180deg);
  transition: transform 0.5s ease;
}
.button.select:hover .button__select {
  top: 100%;
  height: 200%;
  opacity: 1;
  border-color: #826a38;
  transition: height 0.3s ease, opacity 0.5s ease;
}
.button.select .button__icon.location {
  margin-left: 0;
  margin-right: 0.3125rem;
}
.button.select .button__select {
  position: absolute;
  top: 0;
  left: -1px;
  height: 0;
  width: calc(100% + 2px);
  opacity: 0;
  overflow: hidden;
  border: 1px solid #c2aa77;
  border-top: none;
  text-align: center;
  border-radius: 0 0 2px 2px;
  background-color: rgba(255,255,255,0.1);
}
.button.select .button__select-item {
  display: block;
  color: #b4b4b7;
  text-decoration: none;
}
.button.select .button__select-item:hover,
.button.select .button__select-item.active {
  color: #fff;
}
.card {
  width: 1000px;
  padding: 20px;
  margin: auto;
  background-image: url("../images/card.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  box-shadow: 0 5px 54px 0 rgba(0,0,0,0.45);
}
.card a:not(.button) {
  color: #c2aa77;
  text-decoration: none;
}
.card a:not(.button):hover {
  border-bottom: 1px dotted #c2aa77;
}
.card__wrapper {
  border: 4px solid #c2aa77;
  padding: 60px 40px 20px;
  border-radius: 4px;
}
.card__header {
  width: 70%;
  margin: auto;
}
.card__header .h2 {
  color: #c2aa77;
}
.card__main {
  margin-bottom: 40px;
}
.card__logo {
  border: none !important;
}
.card__footer {
  width: 90%;
  margin: auto;
  color: #c2aa77;
  font-size: 16px;
}
.card__footer a:not(.card__logo):hover {
  border-bottom: 1px solid #c2aa77 !important;
}
.card .headline {
  display: inline-block;
}
.card .headline__main {
  font-size: 22px;
}
.card .headline:after {
  left: 0;
  width: 100%;
  height: 2px;
  margin: 0;
}
.steps {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.steps__step {
  position: relative;
  width: 260px;
  text-align: center;
}
.steps__step:first-of-type:after,
.steps__step:last-of-type:after {
  content: '';
  position: absolute;
  top: 19px;
  width: 262px;
  height: 9px;
  background-image: url("../images/card-line.png");
  background-size: 100% 9px;
}
.steps__step:first-of-type:after {
  left: 152px;
}
.steps__step:last-of-type:after {
  right: 152px;
}
.steps__step-order {
  width: 47px;
  height: 47px;
  margin: auto;
  margin-bottom: 10px;
  font-size: 20px;
  color: #c2aa77;
  text-align: center;
  line-height: 45px;
  border: 2px solid #c2aa77;
  border-radius: 100%;
}
.catalog-card {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.catalog-card__item {
  width: 470px;
  height: 240px;
  margin-bottom: 30px;
  padding: 25px 15px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
  font-size: 0;
  transition: box-shadow 0.5s ease;
}
.catalog-card__item:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.25);
}
.catalog-card__item:hover .catalog-card__headline {
  color: #000;
  border-color: #737373;
}
.catalog-card__item--blog {
  position: relative;
  height: 410px;
  padding: 0;
}
.catalog-card__item--blog .catalog-card__content {
  padding: 20px;
  width: 100%;
}
.catalog-card__item--blog .catalog-card__headline--block {
  padding: 14px 20px;
}
.catalog-card__item--blog .catalog-card__headline {
  text-decoration: none;
}
.catalog-card__date,
.catalog-card__tags {
  display: inline-block;
  vertical-align: top;
}
.catalog-card__date {
  width: 92px;
}
.catalog-card__tags {
  width: calc(100% - 92px);
}
.catalog-card__tags a {
  color: #7d7565;
  text-decoration: none;
  border-bottom: 1px solid #d2cfc9;
  padding-bottom: 2px;
  transition: border-color 0.5s ease;
}
.catalog-card__tags a:hover {
  border-color: #7d7565;
}
.catalog-card__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background-color: #fff;
  padding: 18px 20px 0;
  border-top: 1px solid #e2e2e2;
}
.catalog-card__image img {
  width: 100%;
  height: 130px;
}
.catalog-card__avatar,
.catalog-card__content {
  display: inline-block;
  vertical-align: top;
}
.catalog-card__avatar {
  width: 110px;
}
.catalog-card__avatar img {
  width: 110px;
  height: 110px;
  border-radius: 100%;
}
.catalog-card__content {
  width: calc(100% - 110px);
  padding-left: 20px;
  font-size: 16px;
  color: #242528;
  font-weight: 300;
  line-height: 1.7;
}
.catalog-card span {
  color: #7d7565;
  font-size: 14px;
  font-weight: 300;
  vertical-align: middle;
}
.catalog-card .icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background-color: #eee;
  color: #c2aa77;
  border-radius: 100%;
  vertical-align: middle;
}
.catalog-card__text-field {
  margin: 8px 0;
}
.catalog-card__headline {
  color: #242528;
  display: inline-block;
  margin-bottom: 6px;
  font-size: 22px;
  border-bottom: 1px solid #ccc;
  line-height: 1.6;
  font-weight: normal;
  transition: border-color 0.5s ease, color 0.5s ease;
}
.celebs {
  background-color: #f8f4eb;
  padding: 1.5625rem 1.25rem 1.875rem;
  margin: 0.625rem 0;
  font-style: italic;
  border-radius: 6px;
}
.celebs__header {
  font-size: 1.375rem;
  font-weight: 400;
  margin-bottom: 0.625rem;
}
.celebs__items {
  color: #7d7565;
}
.celebs__items span {
  text-transform: uppercase;
}
.celebs__items span:first-child {
  display: none;
}
.course-tabs-list__item {
  padding: 1.875rem 0;
  padding-right: 0.9375rem;
  border-top: 1px solid #333439;
}
.course-tabs-list__item-header {
  color: #fff;
  margin-bottom: 0.625rem;
}
.course-tabs-list__item-header i,
.course-tabs-list__item-header span {
  vertical-align: middle;
  display: inline-block;
}
.course-tabs-list__item-header i {
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 0.9375rem;
  color: #c2aa77;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.4375rem;
  border: 2px solid #c2aa77;
  border-radius: 100%;
}
.course-tabs-list__item-about {
  color: #b4b4b7;
}
.course-tabs-list__item.showAllHelper {
  position: relative;
}
.course-tabs-list__item.showAllHelper:after,
.course-tabs-list__item.showAllHelper:before {
  content: '';
  right: 0;
  position: absolute;
  width: 70%;
}
.course-tabs-list__item.showAllHelper:after {
  top: 50%;
  margin-top: -20px;
  height: 40px;
  border-top: 1px solid #333439;
  border-bottom: 1px solid #333439;
}
.course-tabs-list__item.showAllHelper:before {
  top: 50%;
  margin-top: -1px;
  height: 1px;
  border-bottom: 1px solid #333439;
}
.course-tabs-list__item.showAllHelper .course-tabs-list__item-about {
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 30%;
  background-color: #242528;
  color: #c2aa77;
  border-bottom: 1px dotted #c2aa77;
  cursor: pointer;
}
.course-tabs-list__item.showAllHelper .course-tabs-list__item-about:hover {
  border: none;
  padding-bottom: 1px;
}
.course-table table {
  width: 100%;
  margin-bottom: 0.625rem;
  font-size: 1rem;
  text-align: center;
  color: #b4b4b7;
}
.course-table tr {
  border: 1px solid #4b4d53;
  height: 4.375rem;
}
.course-table tr .button {
  width: 100%;
}
.course-table tr:not(.course-table__thead):not(.course-table__tfooter):hover {
  background-color: #28292c;
}
.course-table tr:not(.course-table__thead):not(.course-table__tfooter) .button {
  border-color: #404040;
  color: #c2aa77 !important;
  background-color: transparent;
}
.course-table tr:not(.course-table__thead):not(.course-table__tfooter) .button:hover {
  color: #242528 !important;
  background-color: #c8a252;
}
.course-table a:not(.button):hover {
  border-bottom: 1px solid;
  padding-bottom: 1px;
}
.course-table td {
  padding: 0 0.9375rem;
}
.course-table td:first-of-type {
  color: #fff;
}
.course-table__thead,
.course-table__tfooter {
  background-color: #2f3033;
  color: #fff;
}
.course-table__tfooter .button {
  color: #242528 !important;
}
.course-table__tfooter .button:hover {
  color: #c2aa77 !important;
}
.course-table__thead {
  height: 3.75rem;
  text-transform: capitalize;
}
.course-table__thead td {
  border: 1px solid #4b4d53;
}
.course-tabs {
  background-color: #242528;
  border-bottom: 1px solid #4b4d53;
}
.course-tabs__header {
  position: relative;
  border-bottom: 1px solid #4b4d53;
  font-size: 0;
  display: flex;
}
.course-tabs__header .container {
  position: relative;
}
.course-tabs__header .container:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 100%;
  background-image: linear-gradient(to right, rgba(36,37,40,0.2), #242528);
}
.course-tabs__header-nav {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  width: 80px;
  height: 40px;
  font-size: 0;
  overflow: hidden;
  border: 1px solid #333439;
  border-radius: 2px;
}
.course-tabs__header-nav-prev,
.course-tabs__header-nav-next {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 100%;
  cursor: pointer;
}
.course-tabs__header-nav-prev:hover,
.course-tabs__header-nav-next:hover {
  background-color: rgba(255,255,255,0.05);
}
.course-tabs__header-nav-prev svg,
.course-tabs__header-nav-next svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  stroke: #c2aa77;
  width: 50%;
  height: 50%;
}
.course-tabs__header-nav-prev {
  border-right: 1px solid #333439;
}
.course-tabs__header .owl-carousel .owl-wrapper-outer {
  bottom: -2px;
}
.course-tabs__header .owl-item {
  display: inline-block;
  vertical-align: bottom;
  float: none;
}
.course-tabs__header-item {
  position: relative;
  z-index: 3;
  display: inline-block;
  padding-right: 5.625rem;
  padding-bottom: 2.5rem;
  color: #86888e;
  font-size: 1.375rem;
  vertical-align: bottom;
  transition: color 0.5s ease, border-color 0.5s ease;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.course-tabs__header-item.active,
.course-tabs__header-item:hover {
  color: #c2aa77;
  border-color: #c2aa77;
}
.course-tabs__body {
  font-size: 0;
}
.course-tabs__main,
.course-tabs__aside {
  font-size: initial;
  display: inline-block;
  vertical-align: top;
}
.course-tabs__main {
  width: 65%;
  border-right: 1px solid #4b4d53;
}
.course-tabs__main-header {
  color: #b4b4b7;
}
.course-tabs__main-header .h2 {
  color: #fff;
  font-size: 1.5625rem;
  line-height: 1.3;
  padding-top: 30px;
}
.course-tabs__main-header__banner {
  position: relative;
  height: 160px;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.course-tabs__main-header__banner:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0), rgba(0,0,0,0.8));
}
.course-tabs__main-header__banner-star {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 10px;
  font-size: 1.375rem;
}
.course-tabs__main-header__banner-star i,
.course-tabs__main-header__banner-star span {
  vertical-align: middle;
}
.course-tabs__main-header__banner-star i {
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 0.9375rem;
  border: 2px solid #c2aa77;
  border-radius: 100%;
}
.course-tabs__main-header__banner-star span {
  display: inline-block;
}
.course-tabs__aside {
  position: relative;
  width: 35%;
  color: #fff;
}
.course-tabs__aside-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transition: top ease 0.1s;
}
.course-tabs__aside .headline {
  margin-bottom: 1.25rem;
}
.course-tabs__aside .headline__other {
  color: #c2aa77 !important;
  font-weight: 300;
}
.course-tabs__aside .headline__text {
  color: #b4b4b7;
}
.course-tabs__aside-header,
.course-tabs__aside-info {
  padding: 2.5rem 0;
  padding-left: 1.375rem;
}
.course-tabs__aside-header {
  border-bottom: 1px solid #4b4d53;
}
.courses {
  display: flex;
}
.courses.light {
  background-color: #fff;
  color: #242528;
  border-bottom: 1px solid #e5e5e6;
}
.courses.light .courses__description .headline__main span:last-of-type {
  color: #000;
}
.courses.dark {
  background-color: #242528;
  color: #b4b4b7;
}
.courses.dark .courses__description .headline__main span:last-of-type {
  color: #fff;
}
.courses__banner {
  flex-basis: 30%;
  height: 100%;
}
@media (min-width: 1280px) {
  .courses__banner {
    flex-basis: 40%;
  }
}
@media (min-width: 1650px) {
  .courses__banner {
    flex-basis: 50%;
  }
}
.courses__banner-image {
  height: inherit;
  background-size: cover;
  background-position: center top;
}
.courses__slider {
  align-self: center;
  padding-left: 6.25rem;
  padding-right: 9.375rem;
  flex-basis: 70%;
}
@media (min-width: 1280px) {
  .courses__slider {
    flex-basis: 60%;
  }
}
@media (min-width: 1650px) {
  .courses__slider {
    flex-basis: 50%;
  }
}
.courses__description-text {
  margin-bottom: 3.125rem;
  height: 200px;
}
.default-section__wrapper {
  display: flex;
  flex-direction: column;
}
.default-section .headline {
  align-self: center;
}
.default-section.left .headline {
  align-self: flex-start;
}
.default-section.right .headline {
  align-self: flex-end;
}
.default-section.light {
  background-color: #fff;
  color: #242528;
}
.default-section.light a {
  color: inherit;
  text-decoration: none;
}
.default-section.grey {
  color: #b4b4b7;
}
.default-section.grey .headline__main {
  color: #fff;
}
.default-section.dark {
  color: #b4b4b7;
  background-color: #242528;
}
.default-section.dark a:not(.button) {
  color: #b4b4b7;
  text-decoration: none;
}
.default-section.dark .headline__main {
  color: #fff;
}
.default-section.dark .headline__other,
.default-section.dark .headline__text {
  color: #b4b4b7;
}
.download-other {
  font-size: 1rem;
  color: #c2aa77;
  text-decoration: none;
}
.download-other:hover span {
  border-color: transparent;
}
.download-other span {
  margin-left: 0.3125rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px dashed #d2cfc9;
  transition: border-color 0.5s ease;
}
.download-other span,
.download-other svg {
  vertical-align: middle;
}
.events {
  font-size: 0;
}
.events__item {
  display: inline-block;
  width: 33.333333333333336%;
  padding: 0 1.5625rem;
  text-align: center;
  color: #b4b4b7;
}
.events__item .button__icon {
  display: none;
}
.events__item-text {
  font-weight: 100;
  margin-bottom: 1.25rem;
  font-size: 1rem;
}
.events__item-image {
  width: 10.625rem;
  height: 10.625rem;
  margin: auto;
  margin-bottom: 0.9375rem;
}
.events__item-image img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
}
.events-header {
  line-height: 1.7;
  margin-bottom: 1.25rem;
}
.events-header__main {
  color: #c2aa77;
  font-size: 1.5rem;
}
.events-header__date {
  font-size: 0.875rem;
  font-family: 'Roboto Condensed', sans-serif;
}
footer {
  padding-top: 3.125rem;
  padding-bottom: 3.75rem;
}
.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.footer__nav {
  flex-basis: 40%;
  text-align: left;
}
.footer__info {
  flex-basis: 50%;
  text-align: right;
}
.footer__info .shared,
.footer__info .subscribe {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}
.footer__info .subscribe {
  margin-right: 1.25rem;
}
.footer__publish,
.footer__address {
  margin-bottom: 2.5rem;
}
.footer__address {
  line-height: 1.4;
}
.footer__address span {
  font-size: 1.125rem;
}
.footer__copy-top {
  text-transform: uppercase;
  font-size: 1.25rem;
}
.footer__copy-icon {
  display: inline-block;
  width: 2.375rem;
  height: 2.375rem;
}
.footer__copy-icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.footer__bottom {
  font-size: 1.125rem;
}
.gallery {
  text-align: left;
  align-self: center;
  margin: auto;
  font-size: 0;
  line-height: 0;
  display: flex;
  flex-flow: row wrap;
}
.gallery-hidden,
.gallery-close {
  display: none;
}
.gallery__item {
  position: relative;
  display: inline-block;
  width: 33.333333333333336%;
  padding: 0.625rem;
}
@media (min-width: 1280px) {
  .gallery__item {
    width: 25%;
  }
}
@media (min-width: 1650px) {
  .gallery__item {
    width: 20%;
  }
  .gallery__item:nth-of-type(11),
  .gallery__item:nth-of-type(12) {
    display: none;
  }
}
.gallery__item:after {
  content: attr(data-description);
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
  padding-left: 1.25rem;
  line-height: 3.125rem;
  font-size: 0.875rem;
  color: #b4b4b7;
  font-family: 'Roboto Condensed', sans-serif;
  text-shadow: 0 2px 3px rgba(0,0,0,0.15);
  background-color: #242528;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.gallery__item:hover:after {
  opacity: 1;
}
.gallery__item-wrapper {
  display: inline-block;
  width: 100%;
  box-shadow: 0 3px 2px 0 rgba(0,0,0,0.25);
  overflow: hidden;
  line-height: 0;
}
.gallery__item img {
  width: 100%;
  line-height: 0;
}
.headline {
  position: relative;
  padding-bottom: 1.875rem;
  margin-bottom: 3.125rem;
  text-align: center;
}
.headline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 140px;
  height: 4px;
  margin-left: -70px;
  background-color: #b4b4b7;
  border-radius: 4px;
}
.headline.left {
  text-align: left;
}
.headline.left:after {
  left: 0;
  margin: 0;
}
.headline.right {
  text-align: right;
}
.headline.right:after {
  left: auto;
  right: 0;
  margin: 0;
}
.headline__other {
  color: #c2aa77;
  font-weight: 300;
}
.headline__text {
  font-size: 1rem;
  color: inherit;
}
.h1 > .headline__main {
  font-weight: 300;
  font-size: 3.75rem;
  line-height: 1.4;
}
.h1 > .headline__other {
  font-size: 1.875rem;
  line-height: 2.5625rem;
}
.h2 > .headline__main {
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1.4;
}
.h2 > .headline__other {
  font-size: 1.5rem;
  line-height: 2.5625rem;
}
.h2.headline:after {
  height: 2px;
}
.alternate {
  padding-bottom: 0;
}
.alternate:after {
  display: none;
}
.alternate > .headline__other {
  font-size: 1.5rem;
  line-height: 2.5625rem;
  color: inherit;
}
.same {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}
.format2 > .headline__main {
  font-weight: 400;
}
.gold.headline:after {
  background-color: #c2aa77;
}
.black .headline__other {
  color: #242528;
}
.icon {
  display: inline-block;
}
.icon--small svg {
  width: 12px;
  height: 12px;
  color: currentColor;
}
.icons-table {
  margin-bottom: 10px;
}
.icons-table table {
  width: 100%;
}
.icons-table tr {
  height: 11.875rem;
}
.icons-table td {
  width: 25%;
  text-align: center;
  border: 1px solid #414245;
  vertical-align: middle;
}
.icons-table td img {
  max-width: 70%;
  max-height: 70%;
}
.icons-table.alternate td {
  border-color: #2a2b2f;
}
.icons-table.alternate tr {
  height: 6.875rem;
}
.icons-table.double {
  font-size: 0;
}
.icons-table.double .td {
  position: relative;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #414245;
  border-left: 1px solid #414245;
  height: 11.875rem;
}
.icons-table.double .td img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 70%;
  max-height: 70%;
}
.icons-table.double .td:nth-child(2n) {
  border-right: 1px solid #414245;
}
.icons-table.double .td:nth-child(1),
.icons-table.double .td:nth-child(2) {
  border-top: 1px solid #414245;
}
.info__item {
  display: block;
  font-weight: 300;
  color: #b4b4b7;
  text-decoration: none;
  line-height: 2;
}
.info__item span {
  padding-bottom: 2px;
  border-bottom: 1px solid #585858;
}
.info__item:hover span {
  border: none;
}
.list {
  padding-left: 0;
  list-style: none;
}
.list li {
  position: relative;
  margin-bottom: 10px;
  text-indent: 20px;
  line-height: 1.875rem;
}
.list li:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0.875rem;
  margin-top: -0.1875rem;
  width: 6px;
  height: 6px;
  background-color: #c2aa77;
  border-radius: 100%;
}
.modal {
  width: 970px;
  font-size: 0;
  text-align: left;
}
.modal__main,
.modal__image {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  height: 550px;
}
.modal__main {
  width: 60%;
  padding: 2.5rem 1.875rem;
}
.modal__image {
  position: relative;
  width: 40%;
}
.modal__image-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #b4b4b7;
  border-radius: 1px;
  background-image: url("../images/jimmy_modal.png");
  background-position: center;
  backbround-size: cover;
}
.modal__image-item {
  height: 100%;
  width: 100%;
  border: 2px solid #c2aa77;
  border-radius: 1px;
}
.modal__image-item-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  text-align: center;
  color: #fff;
}
.modal__image-item-text .h2 {
  color: #c2aa77;
}
.modal_form-input {
  height: 38px;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid #d7d7d7;
  border-radius: 1px;
  font-size: 1.125rem;
  text-indent: 10px;
  font-weight: 300;
}
.modal_form-input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.modal_form-input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.modal_form-input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.modal_form-input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.modal_form-input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal_form-input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal_form-input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal_form-input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal_form-input.fake {
  position: relative;
  line-height: 38px;
  color: #7d7565;
}
.modal_form-input .fake-file {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 38px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 1px;
  cursor: pointer;
}
.modal_form-input .fake-file:after,
.modal_form-input .fake-file:before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #c2aa77;
}
.modal_form-input .fake-file:after {
  width: 16px;
  height: 2px;
}
.modal_form-input .fake-file:before {
  width: 2px;
  height: 16px;
}
.modal_form-input .fake-file input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.modal_form-input:hover {
  border-color: #b2b2b2;
}
.modal_form-row {
  margin-bottom: 1.25rem;
  overflow: hidden;
}
.modal_form-row:after {
  content: '';
  overflow: hidden;
  clear: both;
}
.modal_form_2 .modal_form-input {
  width: 48%;
}
.modal_form_2 .modal_form-input:first-child {
  float: left;
}
.modal_form_2 .modal_form-input:last-child {
  float: right;
}
.modal_form_3 {
  text-align: center;
}
.modal_form_3 .modal_form-input {
  width: 36%;
}
.modal_form_3 .modal_form-input:first-child {
  float: left;
}
.modal_form_3 .modal_form-input:last-child {
  float: right;
  width: 20%;
}
::-webkit-input-placeholder {
  color: #7d7565;
}
::-moz-placeholder {
  color: #7d7565 b;
}
:-moz-placeholder {
  color: #7d7565 b;
}
:-ms-input-placeholder {
  color: #7d7565;
}
.nav-table {
  width: 100%;
}
.nav-table__header {
  font-size: 0;
  border-bottom: 1px solid #b4b4b7;
}
.nav-table__header-item {
  display: inline-block;
  width: 33.333333333333336%;
  font-size: 1.125rem;
  font-weight: 400;
}
.nav-table__wrapper {
  font-size: 0;
}
.nav-table__wrapper ul {
  display: inline-block;
  padding-left: 0;
  width: 33.333333333333336%;
  font-size: 1rem;
  font-weight: 300;
  list-style: none;
  vertical-align: top;
}
.nav-table__wrapper ul a {
  text-decoration: none;
  white-space: nowrap;
}
.nav-table__wrapper ul a:hover {
  text-decoration: underline;
}
.navigation {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  width: 100%;
  min-width: 1024px;
  padding: 0 6.5rem;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
}
.navigation__logo {
  position: absolute;
  left: 3.5625rem;
  top: 50%;
  margin-top: -25px;
}
.navigation__links {
  margin-right: 17px;
}
.navigation__links ul {
  list-style: none;
}
.navigation__links ul li {
  display: inline-block;
}
.navigation__links ul li.active a {
  position: relative;
  color: #fff;
}
.navigation__links ul li.active a:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 36px;
  border-bottom: 2px solid #c2aa77;
}
.navigation__links ul a {
  display: inline-block;
  margin: 0 17px;
  color: #d9d8d6;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.5s ease;
}
.navigation__links ul a:hover {
  color: #fff;
}
.grey-nav {
  padding-top: 115px;
}
.grey-nav .navigation {
  background-color: #2f3033;
  height: 80px;
}
.numeric-list {
  margin-top: 1.5625rem;
}
.numeric-list__item {
  margin-bottom: 25px;
  font-style: italic;
  vertical-align: middle;
}
.numeric-list__item:last-child .numeric-list__item-num:after {
  display: none;
}
.numeric-list__item-num {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 0.625rem;
  vertical-align: middle;
  font-style: normal;
  text-align: center;
  color: #c2aa77;
  line-height: 3rem;
  font-size: 1.375rem;
  border: 2px solid #c2aa77;
  border-radius: 100%;
}
.numeric-list__item-num:after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -4px;
  width: 9px;
  height: 15px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpENTVGM0JFRkU5NzIxMUU1OEFEN0QxNjE3REFGOTNFMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENTVGM0JGMEU5NzIxMUU1OEFEN0QxNjE3REFGOTNFMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ1NUYzQkVERTk3MjExRTU4QUQ3RDE2MTdEQUY5M0UwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ1NUYzQkVFRTk3MjExRTU4QUQ3RDE2MTdEQUY5M0UwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+fp1rKAAAAINJREFUeNpi+v//PwMyPrOm9D+6GBMDEWDIKmIEehld7D9IHN2kRUCshcMQkPgikKKDQLwUiNnQFLBBxQ+CFM0F4vtA3IKmqAUqPpcFKpAKxBeBeDuU7wjEUUCsj+y7t0CcBMQLoPwFUP5b9CDYBcTrgfgTlN6FK5z6gfgJlIYDgAADALjIMqy9NoaPAAAAAElFTkSuQmCC");
}
.numeric-list__item-text {
  display: inline-block;
  width: calc(100% - 4.125rem);
  vertical-align: middle;
}
.numeric-list__item a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted #b4b4b7;
}
.numeric-list__item a:hover {
  border: none;
}
.numeric-list__item span {
  color: #b4b4b7;
}
.payments {
  font-size: 0;
}
.payments__item {
  display: inline-block;
  width: 20%;
  vertical-align: middle;
  text-align: center;
}
.payments__item img {
  line-height: 0;
  max-width: 70%;
}
.prices {
  width: 1000px;
  margin: auto;
  text-align: center;
}
@media (min-width: 1650px) {
  .prices {
    width: 1280px;
  }
}
.prices__item {
  position: relative;
  padding: 0 1.25rem;
  padding-bottom: 10rem;
  height: 33.75rem;
  color: #b4b4b7;
  border: 2px solid #c2aa77;
  border-radius: 1px;
}
.prices__item .button {
  color: #242528;
}
.prices__item .button:hover {
  background-color: #c8a252;
  color: #242528;
}
.prices__item-header {
  color: #b4b4b7;
  text-align: center;
  font-size: 1.375rem;
  height: 10rem;
  margin-bottom: 1.875rem;
}
.prices__item-header-wrapper {
  position: relative;
  padding: 2.1875rem 0 1.875rem;
}
.prices__item-header-wrapper:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 42px;
  height: 2px;
  margin: auto;
  background-color: #c2aa77;
}
.prices__item-header-type {
  color: #fff;
}
.prices__item-list-prices {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  margin-bottom: 0.625rem;
}
.prices__item-list-prices:before {
  content: '';
  position: absolute;
  bottom: 0.5625rem;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed #515151;
}
.prices__item-list-prices:after {
  content: '';
  width: 100%;
  display: table-cell;
  overflow: hidden;
  clear: both;
}
.prices__item-list-prices__name,
.prices__item-list-prices__wrapper {
  position: relative;
  z-index: 1;
  background: #2f3033;
}
.prices__item-list-prices__name {
  float: left;
}
.prices__item-list-prices__wrapper {
  float: right;
  padding-left: 2px;
}
.prices__item-list-prices__price {
  display: inline-block;
  font-size: 1.375rem;
  border-right: 1px solid #b4b4b7;
  padding-right: 0.3125rem;
  line-height: 1;
}
@media (min-width: 1650px) {
  .prices__item-list-prices__price {
    font-size: 1.125rem;
  }
}
.prices__item-list-prices__price:first-child {
  color: #c2aa77;
}
.prices__item-list-prices__price:last-child {
  border: none;
}
.prices__item-list-prices__price.eur:after {
  content: '€';
}
.prices__item-list-prices__price.rur:after {
  content: 'P';
}
.prices__item-list-prices.outline .prices__item-list-prices__name {
  border-bottom: 1px dotted;
}
.prices__item-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  heihgt: 10rem;
  padding: 2.1875rem 1.25rem;
}
.prices__item-footer-colors {
  margin-bottom: 1.875rem;
}
.prices__item-footer-colors__untill,
.prices__item-footer-colors__after {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  margin-left: 5px;
}
.prices__item-footer-colors__untill:after,
.prices__item-footer-colors__after:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5px;
  width: 10px;
  height: 10px;
}
.prices__item-footer-colors__untill:after {
  background-color: #c2aa77;
}
.prices__item-footer-colors__after:after {
  background-color: #b4b4b7;
}
.prices__container {
  z-index: 10;
  display: inline-block;
  width: 300px;
  vertical-align: top;
  margin: 0 0.625rem 1.875rem;
}
@media (min-width: 1650px) {
  .prices__container {
    width: 350px;
  }
}
.prices__wrapper {
  background-color: #2f3033;
  padding: 10px;
  border: 1px solid #484a50;
  border-radius: 1px;
  box-shadow: 5px 5px 54px 5px rgba(0,0,0,0.35);
  transition: transform ease 0.3s;
}
.prices__wrapper.pack {
  width: 98%;
}
.prices__wrapper-helper-1,
.prices__wrapper-helper-2 {
  position: absolute;
  width: 98%;
  height: 100%;
  background-color: #2f3033;
  border: 1px solid #484a50;
  border-radius: 1px;
}
.prices__wrapper-helper-1 {
  z-index: -2;
  left: 10px;
  top: 10px;
}
.prices__wrapper-helper-2 {
  z-index: -1;
  left: 5px;
  top: 5px;
}
.prices__wrapper:hover {
  background-color: #fff;
  transform: scale(1.04);
}
.prices__wrapper:hover .prices__item {
  color: #242528;
}
.prices__wrapper:hover .prices__item-header {
  color: #242528;
}
.prices__wrapper:hover .prices__item-header-wrapper:after {
  background-color: #c8a252;
}
.prices__wrapper:hover .prices__item-header-type {
  color: #242528;
}
.prices__wrapper:hover .prices__item-list-prices__price {
  color: #b4b4b7;
}
.prices__wrapper:hover .prices__item-list-prices__price:first-child {
  color: #c8a252;
}
.prices__wrapper:hover .prices__item-list-prices__wrapper,
.prices__wrapper:hover .prices__item-list-prices__name {
  background: #fff;
}
.prices__wrapper:hover .prices__item-footer-colors__untill:after {
  background-color: #c8a252;
}
.prizes {
  text-align: center;
  margin-bottom: 2.5rem;
}
.prizes__container {
  display: inline-block;
  margin: 0 0.625rem 1.25rem;
  vertical-align: top;
  text-align: center;
}
.prizes__container:first-child {
  display: block;
}
.prizes__wrapper {
  display: inline-block;
  background-color: #242528;
  padding: 0.625rem;
  border: 1px solid #b4b4b7;
  border-radius: 1px;
}
.prizes__item {
  position: relative;
  min-height: 626px;
  width: 436px;
  padding: 1.5625rem 3.125rem 260px;
  border: 2px solid #c2aa77;
  border-radius: 1px;
}
.prizes__item-icon {
  margin-bottom: 0.9375rem;
}
.prizes__item .headline__main {
  font-size: 1.375rem;
}
.prizes__item .headline:after {
  width: 70px;
  margin-left: -35px;
}
.prizes__item-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 240px;
  background-image: radial-gradient(rgba(194,170,119,0.7), rgba(194,170,119,0.4), rgba(194,170,119,0.1), rgba(194,170,119,0) 66%);
}
.prizes__item-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.prizes_block .h3,
.prizes_block .button {
  margin-bottom: 1.25rem;
}
.prizes_block a:not(.button) {
  color: #c2aa77 !important;
  border-bottom: 1px dotted #c2aa77;
}
.prizes_block a:not(.button):hover {
  border: none;
}
.prizes__container.result {
  width: 100%;
  color: #fff;
  text-align: left;
  margin: 0 0 2.5rem;
}
.prizes__container.result .prizes__wrapper,
.prizes__container.result .prizes__item {
  width: 100%;
}
.prizes__container.result .prizes__item {
  height: auto;
  min-height: auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.prizes__container.result .prizes__avatar {
  border-radius: 100%;
  overflow: hidden;
}
.prizes__container.result .prizes__avatar_block {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 3.125rem;
  text-align: left;
}
.prizes__container.result .prizes__position {
  position: absolute;
  top: 0;
  right: 3.125rem;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-align: center;
  background-color: #c2aa77;
  border: 2px solid #242528;
  border-radius: 100%;
}
.prizes__container.result .prizes__position svg {
  display: inline-block;
  width: 5px;
  height: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  vertical-align: middle;
}
.prizes__container.result .prizes__person_block {
  display: inline-block;
  width: 70%;
  vertical-align: top;
}
.prizes__container.result .prizes__person_block .h3 {
  margin-bottom: 1.25rem;
}
.prizes__container.result .prizes__person_block .celebs {
  background-color: #6a624f;
}
.prizes__container.result .prizes__person_block .celebs__header {
  color: #c2aa77;
}
.prizes__container.result .prizes__person_block .celebs__header svg {
  display: inline-block;
  width: 1.5625rem;
  height: 1.5625rem;
  vertical-align: middle;
}
.prizes__container.result .prizes__person_block .celebs__header span {
  display: inline-block;
  vertical-align: middle;
}
.prizes__container.result .prizes__person_block .celebs__items {
  text-transform: none;
  color: #9f9e99;
}
.prizes__container.result .prizes__person_block .celebs__items a {
  color: inherit;
}
.prizes__container.result .prizes__person_block .celebs__items a:hover {
  text-decoration: none;
}
.prizes__container.result .prizes__person_block .celebs__items span:first-child {
  display: inline-block;
}
.prizes__container.result .prizes__person_block small {
  display: inline-block;
  color: #c2aa77;
  margin-bottom: 1.25rem;
}
.produser {
  width: 100%;
}
.produser__content {
  width: 70%;
  margin: 0 auto 2.5rem;
  text-align: center;
}
.produser__image {
  position: relative;
}
.produser__image:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.8));
}
.produser__headline {
  position: absolute;
  bottom: 1.25rem;
  left: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: #242528;
  font-size: 1.375rem;
}
.produser__mix {
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.2);
}
.profile .banner,
.profile .banner__item {
  height: 80vh !important;
}
.profile__content {
  padding: 75px 0;
  background-color: #fff;
  font-size: 0;
}
.profile__content-main,
.profile__content-aside {
  display: inline-block;
  font-size: 16px;
  vertical-align: top;
}
.profile__content-main {
  width: 60%;
  padding-right: 25px;
}
.profile__content-aside {
  width: 40%;
}
.profile__content .content-field {
  line-height: 2;
}
.questions {
  background-color: #c2aa77;
  padding-top: 1.875rem;
  padding-bottom: 2.5rem;
}
.questions__header {
  font-size: 1.375rem;
  font-weight: 400;
}
.questions__text {
  margin-bottom: 10px;
}
.questions.light {
  position: relative;
  color: #b4b4b7;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.questions.light:after,
.questions.light:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 25%;
}
.questions.light:after {
  background-image: linear-gradient(to right, rgba(0,0,0,0), #000);
  right: 0;
}
.questions.light:before {
  background-image: linear-gradient(to left, rgba(0,0,0,0), #000);
  left: 0;
}
.questions.light * {
  position: relative;
  z-index: 2;
}
.questions.light .questions__header {
  font-size: 2.5rem;
  line-height: 1.4;
  color: #fff;
}
.questions.light .questions__date {
  color: #c2aa77;
  line-height: 2;
  font-size: 1.375rem;
}
.questions.light .questions__text {
  font-size: 1rem;
}
.reports__wrapper {
  display: flex;
  flex-direction: column;
}
.reports__wrapper .headline,
.reports__wrapper .tabs {
  align-self: center;
}
.reports__wrapper .tabs {
  margin-bottom: 35px;
}
.reports__wrapper .gallery {
  margin-bottom: 50px;
}
.reports__wrapper .details {
  padding-left: 0.625rem;
  align-self: center;
}
.schedule {
  width: 45%;
  min-width: 900px;
  margin: auto;
  margin-bottom: 5.625rem;
  color: #b4b4b7;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.15);
}
.schedule .h4 {
  color: #fff;
  margin-bottom: 1.25rem;
}
.schedule__body {
  padding: 2.5rem 5rem 1.25rem;
  width: 100%;
  background-color: #242528;
  border-radius: 2px 2px 0 0;
}
.schedule__body .td:nth-of-type(2),
.schedule__body .td:nth-of-type(3) {
  width: 30%;
}
.schedule__body .td:nth-of-type(1),
.schedule__body .td:nth-of-type(4) {
  width: 20%;
}
.schedule__body.info .td {
  width: 33.333333333333336%;
}
.schedule__body .tr {
  border-bottom: 1px solid #414245;
  transition: background-color 0.5s ease;
  font-size: 0;
}
.schedule__body .tr:last-of-type {
  border: none;
}
.schedule__body .tr:hover {
  background-color: #2f3033;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
}
.schedule__body .tr .td {
  display: inline-block;
  padding: 0.625rem;
  vertical-align: middle;
  font-size: 1rem;
}
.schedule__body .tr .td:first-of-type {
  padding-left: 1.875rem;
  color: #fff;
}
.schedule__body .tr .td:last-of-type {
  text-align: right;
}
.schedule__body .tr .td .button {
  border: none;
  color: rgba(0,0,0,0);
}
.schedule__body .tr .td .button__icon {
  width: 1.25rem;
  height: 1.25rem;
}
.schedule__body .tr .td .button svg {
  stroke: #c2aa77;
}
.schedule__body .tr .td .button:hover {
  color: #404040;
}
.schedule__body .tr .td .button:hover svg {
  stroke: #242528;
}
.schedule__footer {
  padding: 1.5625rem 6.5625rem 2.5rem;
  background-color: #2f3033;
  border-top: 1px solid #414245;
  border-radius: 0 0 2px 2px;
}
.separator {
  padding: 0 0.625rem;
}
.schedule-teachers {
  position: relative;
  background-image: url("../images/schedule.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #0c1114;
  color: #fff;
}
.schedule-teachers:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(14,18,21,0) 0%, #0e1215 30%, #0d1114 100%);
}
.schedule-teachers__wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.schedule-teachers__wrapper .headline,
.schedule-teachers__wrapper .tabs {
  align-self: center;
  text-align: center;
}
.schedule-teachers__wrapper .tabs {
  margin-bottom: 1.25rem;
}
.schedule-teachers__wrapper .schedule {
  align-self: center;
}
.scroll-down {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
  z-index: 3;
  margin: auto;
  width: 1.25rem;
  height: 3.4375rem;
  cursor: pointer;
}
.scroll-down.static {
  position: static;
}
.scroll-down svg {
  width: 100%;
  height: 100%;
}
.shared__item {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 4px;
  color: #c2aa77;
  border: 1px solid #b4b4b7;
  border-radius: 1px;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.shared__item:hover {
  background-color: #c2aa77;
  color: #242528;
}
.shared__item svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 70%;
  height: 70%;
  fill: currentColor;
}
.sorry {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;
  background-color: #242528;
  box-shadow: 2px 0 2px rgba(0,0,0,0.6);
  color: #b4b4b7;
  line-height: 44px;
}
.sorry span {
  display: inline-block;
  vertical-align: middle;
}
.sorry__icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 20px;
  border: 0.125rem solid #c2aa77;
  font-weight: 400;
  color: #c2aa77;
  border-radius: 100%;
  text-align: center;
}
.subscribe input,
.subscribe__send {
  display: inline-block;
  height: 2.5rem;
  color: #c2aa77;
  vertical-align: middle;
  border: 1px solid #b4b4b7;
}
.subscribe input {
  width: 15rem;
  padding-left: 15px;
  border-radius: 1px 0 0 1px;
  outline: none;
}
.subscribe input::-webkit-input-placeholder {
  opacity: 1;
  color: #c2aa77;
  transition: opacity 0.3s ease;
}
.subscribe input::-moz-placeholder {
  opacity: 1;
  color: #c2aa77;
  transition: opacity 0.3s ease;
}
.subscribe input:-moz-placeholder {
  opacity: 1;
  color: #c2aa77;
  transition: opacity 0.3s ease;
}
.subscribe input:-ms-input-placeholder {
  opacity: 1;
  color: #c2aa77;
  transition: opacity 0.3s ease;
}
.subscribe input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.subscribe input:focus::-moz-placeholder {
  opacity: 0;
}
.subscribe input:focus:-moz-placeholder {
  opacity: 0;
}
.subscribe input:focus:-ms-input-placeholder {
  opacity: 0;
}
.subscribe__send {
  position: relative;
  width: 2.5rem;
  border-left: none;
  border-radius: 0 1px 1px 0;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.subscribe__send:hover {
  background-color: #c2aa77;
  color: #242528;
}
.subscribe__send svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 40%;
  height: 40%;
  stroke: currentColor;
}
.tabs__item {
  display: inline-block;
  min-width: 150px;
  border: 1px solid #c2aa77;
  border-right: none;
  color: #c2aa77;
  line-height: 2.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.125rem;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.tabs__item:first-of-type {
  border-radius: 2px 0 0 2px;
}
.tabs__item:last-of-type {
  border-right: 1px solid #c2aa77;
  border-radius: 0 2px 2px 0;
}
.tabs__item.active,
.tabs__item:hover {
  background-color: #c2aa77;
  color: #404040;
}
.tabs__item:hover {
  background-color: #b6944d;
}
.tabs.light .tabs__item {
  border-color: #fff;
  color: #fff;
}
.tabs.light .tabs__item.active,
.tabs.light .tabs__item:hover {
  color: #c2aa77;
  background-color: #fff;
}
.tags {
  margin-bottom: 30px;
  font-weight: 300;
  color: #86888e;
  font-size: 0;
}
.tags__headline,
.tags__content {
  display: inline-block;
  font-size: 1rem;
  vertical-align: top;
}
.tags__headline {
  width: 50px;
}
.tags__content {
  width: calc(100% - 50px);
}
.tags__tag {
  position: relative;
  display: inline-block;
  line-height: 1.875rem;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-right: 18px;
  padding-left: 10px;
  background-color: #e2e2e2;
  border-radius: 2px;
  text-decoration: none;
  color: #86888e;
}
.tags__tag:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 9px;
  margin-top: -1px;
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: #86888e;
}
.tags__tag.active {
  color: #242528;
  background-color: #c2aa77;
}
.teachers {
  align-self: center;
  text-align: center;
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.teachers__wrapper {
  position: relative;
  padding: 0.625rem;
  display: inline-block;
  width: 50%;
  height: 18.75rem;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .teachers__wrapper {
    width: 33.333333333333336%;
  }
}
.teachers__item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: left;
  background-size: cover;
  background-position: center top;
  border-radius: 2px;
}
.teachers__item:hover .teachers__item-wrapper {
  height: 100%;
  background-color: rgba(97,97,97,0.8);
}
.teachers__item:hover .teachers__item-groups,
.teachers__item:hover .teachers__item-dates {
  opacity: 1;
}
.teachers__item-wrapper {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 38%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  overflow: hidden;
  transition: all 0.5s ease;
  background-color: rgba(47,48,51,0.8);
}
.teachers__item-name {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
  font-size: 1.625rem;
  font-weight: 100;
  border-bottom: 1px solid #b4b4b7;
  padding-bottom: 0.4375rem;
  margin-bottom: 0.5625rem;
}
.teachers__item-about,
.teachers__item-groups,
.teachers__item-dates {
  display: block;
}
.teachers__item-about,
.teachers__item-dates {
  color: #c2aa77;
  font-size: 0.9375rem;
  font-weight: 400;
}
.teachers__item-about .separator,
.teachers__item-dates .separator {
  padding: 0 0.3125rem;
}
.teachers__item-about {
  line-height: 1.2;
}
.teachers__item-dates {
  color: #fff;
  font-weight: 300;
}
.teachers__item-groups {
  color: #fff;
  font-size: 0.875rem;
  font-family: 'Roboto Condensed', sans-serif;
}
.teachers__item-groups svg {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3125rem;
  vertical-align: middle;
}
.teachers__item-groups span {
  vertical-align: middle;
}
.teachers__item-dates,
.teachers__item-groups {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.tickets .button.alternate {
  margin-bottom: 3.125rem;
}
