@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.events
  font-size 0

  &__item
    display inline-block
    width (100 / 3)%
    padding 0 remConvert(25)
    text-align center
    color _milk

    .button__icon
      display none

    &-text
      font-weight _thin
      margin-bottom remConvert(20)
      fontConvert(16)

    &-image
      width remConvert(170)
      height remConvert(170)
      margin auto
      margin-bottom remConvert(15)


      img
        border-radius 100%
        width 100%
        height 100%
        box-shadow 0 2px 3px 0 rgba(0,0,0,.25)

.events-header
  line-height 1.7
  margin-bottom remConvert(20)

  &__main
    color _gold
    fontConvert(24)

  &__date
    fontConvert(14)
    font-family _condensed
