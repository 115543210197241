@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.schedule-teachers
  position relative
  background-image url('../images/schedule.jpg')
  background-size 100% auto
  background-repeat no-repeat
  background-color #0c1114
  color #fff

  &:before
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-image linear-gradient(rgba(14,18,21,0) 0%, rgba(14,18,21, 1) 30%, rgba(13,17,20, 1) 100%)

  //&:after
  //тут будет паттерн

  &__wrapper
    position relative
    z-index 2
    display flex
    flex-direction column
    width 100%

    .headline
    .tabs
      align-self center
      text-align center

    .tabs
      margin-bottom remConvert(20)

    .schedule
      align-self center
