@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.tabs
  &__item
    display inline-block
    min-width 150px
    border 1px solid _gold
    border-right none
    color _gold
    line-height remConvert(40)
    text-align center
    text-decoration none
    fontConvert(18)
    transition background-color .5s ease, color .5s ease

    &:first-of-type
      border-radius 2px 0 0 2px

    &:last-of-type
      border-right 1px solid _gold
      border-radius 0 2px 2px 0

    &.active
    &:hover
      background-color _gold
      color _button-hover

    &:hover
      background-color #b6944d

  &.light
    .tabs
      &__item
        border-color #fff
        color #fff

        &.active
        &:hover
          color _gold
          background-color #fff