@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.shared
  &__item
    position relative
    display inline-block
    width remConvert(40)
    height remConvert(40)
    margin 0 4px
    color _gold
    border 1px solid _milk
    border-radius 1px
    transition background-color .5s ease, color .5s ease

    &:hover
      background-color _gold
      color _main-gray

    svg
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      margin auto
      width 70%
      height 70%
      fill currentColor