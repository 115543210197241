@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.subscribe
  input
  &__send
    display inline-block
    height remConvert(40)
    color _gold
    vertical-align middle
    border 1px solid _milk

  input
    width remConvert(240)
    padding-left 15px
    border-radius  1px 0 0 1px
    outline none

    +placeholder()
      opacity 1
      color _gold
      transition opacity .3s ease

    &:focus
      +placeholder()
        opacity 0

  &__send
    position relative
    width remConvert(40)
    border-left none
    border-radius 0 1px 1px 0
    transition background-color .5s ease, color .5s ease

    &:hover
      background-color _gold
      color _main-gray

    svg
      position absolute
      top 0
      bottom 0
      right 0
      left 0
      margin auto
      width 40%
      height 40%
      stroke currentColor