@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.questions
  background-color _gold
  padding-top remConvert(30)
  padding-bottom remConvert(40)

  &__header
    fontConvert(22)
    font-weight _regular

  &__text
    margin-bottom 10px

  &.light
    position relative
    color _milk
    overflow hidden
    background-size cover
    background-position center

    &:after
    &:before
      content ''
      position absolute
      top 0
      bottom 0
      height 100%
      width 25%

    &:after
      background-image linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1))
      right 0

    &:before
      background-image linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1))
      left 0

    *
      position relative
      z-index 2

    .questions
      &__header
        fontConvert(40)
        line-height 1.4
        color #fff

      &__date
        color _gold
        line-height 2
        fontConvert(22)

      &__text
        fontConvert(16)
