@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.produser
  width 100%

  &__content
    width 70%
    margin 0 auto remConvert(40)
    text-align center

  &__image
    position relative

    &:after
      content ''
      position absolute
      bottom 0
      left 0
      width 100%
      height remConvert(80)
      background-image linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.8))

  &__headline
    position absolute
    bottom remConvert(20)
    left 0
    z-index 10
    width 100%
    text-align center
    color #242528
    font-size remConvert(22)

  &__mix
    box-shadow 0 2px 15px 0 rgba(0,0,0,.2)
