@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.teachers
  align-self center
  text-align center
  font-size 0
  display flex
  flex-flow row wrap
  justify-content center

  &__wrapper
    position relative
    padding remConvert(10)
    display inline-block
    width 50%
    height remConvert(300)
    overflow: hidden

    @media (min-width 1280px)
      width (100 / 3)%

  &__item
    position relative
    display inline-block
    width 100%
    height 100%
    text-align left
    background-size cover
    background-position center top
    border-radius 2px

    &:hover
      .teachers__item
        &-wrapper
          height 100%
          background-color _tchr-hover(.8)

        &-groups
        &-dates
          opacity 1

    &-wrapper
      position: absolute
      z-index 2
      left 0
      bottom 0
      width 100%
      height 38%
      padding-top remConvert(20)
      padding-bottom remConvert(20)
      padding-left remConvert(30)
      padding-right remConvert(30)
      overflow hidden
      transition all .5s ease
      background-color _tchr(.8)

    &-name
      display inline-block
      text-transform uppercase
      color #fff
      white-space nowrap
      fontConvert(26)
      font-weight _thin
      border-bottom 1px solid _milk
      padding-bottom remConvert(7)
      margin-bottom remConvert(9)

    &-about
    &-groups
    &-dates
      display block

    &-about
    &-dates
      color _gold
      fontConvert(15)
      font-weight _regular

      .separator
        padding 0 remConvert(5)

    &-about
      line-height 1.2

    &-dates
      color #fff
      font-weight _light
      fontConcert(16)

    &-groups
      color #fff
      fontConvert(14)
      font-family _condensed

      svg
        display inline-block
        width remConvert(14)
        height remConvert(14)
        margin-right remConvert(5)
        vertical-align middle

      span
        vertical-align middle

    &-dates
    &-groups
      opacity 0
      transition opacity .5s ease
