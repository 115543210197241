@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.modal
  width 970px
  font-size 0
  text-align left

  &__main
  &__image
    display inline-block
    vertical-align top
    fontConvert(16)
    height 550px

  &__main
    width 60%
    padding remConvert(40) remConvert(30)


  &__image
    position relative
    width 40%

    &-wrapper
      position absolute
      height 100%
      width 100%
      padding remConvert(10)
      border 1px solid _milk
      border-radius 1px
      background-image url('../images/jimmy_modal.png')
      background-position center
      backbround-size cover

    &-item
      height 100%
      width 100%
      border 2px solid _gold
      border-radius 1px

      &-text
        position absolute
        bottom 0
        left 0
        right 0
        margin auto
        padding 20px
        text-align center
        color #fff

        .h2
          color _gold



  &_form

    &-input
      height 38px
      width 100%
      background-color #fbfbfb
      border 1px solid #d7d7d7
      border-radius 1px
      fontConvert(18)
      text-indent 10px
      font-weight _light

      &::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
      &::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
      &:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
      &:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
      &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
      &:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
      &:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
      &:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

      &.fake
        position relative
        line-height 38px
        color #7d7565

      .fake-file
        position absolute
        top -1px
        right -1px
        width 38px
        height 38px
        background-color #fff
        border 1px solid #b2b2b2
        border-radius 1px
        cursor pointer

        &:after
        &:before
          content ''
          position absolute
          z-index 0
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          background-color _gold

        &:after
          width 16px
          height 2px

        &:before
          width 2px
          height 16px


        input
          position absolute
          top 0
          left 0
          bottom 0
          right 0
          z-index 1
          width 100%
          height 100%
          opacity 0
          cursor pointer

      &:hover
        border-color #b2b2b2

    &-row
      margin-bottom remConvert(20)
      overflow hidden

      &:after
        content ''
        overflow hidden
        clear both

    &_2
      .modal_form-input
        width 48%

        &:first-child
          float left

        &:last-child
          float right

    &_3
      text-align center

      .modal_form-input
        width 36%

        &:first-child
          float left

        &:last-child
          float right
          width 20%



::-webkit-input-placeholder {color:#7d7565;}
::-moz-placeholder          {color:#7d7565b;}/* Firefox 19+ */
:-moz-placeholder           {color:#7d7565b;}/* Firefox 18- */
:-ms-input-placeholder      {color:#7d7565;}