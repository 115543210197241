@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.anchor-list
  margin-bottom 30px

  &__chars
    font-size 0

    a
      position relative
      display inline-block
      padding-bottom 15px
      font-size remConvert(22)
      text-transform uppercase
      font-weight 300
      color #cecfd1
      text-decoration none
      text-align center
      transition color .5s ease

      &:after
        content ''
        position absolute
        bottom 0
        left 0
        width 100%
        border-bottom 1px solid #e2e2e2

      &:hover
        color #a0a2a6

      &.active
        color _gold

        &:after
          bottom -1px
          border-bottom 2px solid _gold

    &--en
      a
        width (100 / 24)%

    &--ru
      a
        width 33px
