@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.icon
  display inline-block


  &--small
    svg
      width 12px
      height 12px
      color currentColor

