@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.prizes
  text-align center
  margin-bottom remConvert(40)

  &__container
    display inline-block
    margin 0 remConvert(10) remConvert(20)
    vertical-align top
    text-align center

    &:first-child
      display block

  &__wrapper
    display inline-block
    background-color _main-gray
    padding remConvert(10)
    border 1px solid _milk
    border-radius 1px

  &__item
    position relative
    min-height 626px
    width 436px
    padding remConvert(25) remConvert(50) 260px
    border 2px solid _gold
    border-radius 1px

    &-icon
      margin-bottom remConvert(15)

    .headline
       &__main
         fontConvert(22)

       &:after
         width 70px
         margin-left -35px
         
     &-image
       position absolute
       bottom 0
       left 0
       right 0
       width 100%
       height 240px
       background-image radial-gradient(rgba(194, 170, 119, .7), rgba(194, 170, 119, .4), rgba(194, 170, 119, .1), rgba(194, 170, 119, 0) 66%)

       img
         position absolute
         top 0
         left 0
         right 0
         bottom 0
         margin auto

   &_block
     .h3
     .button
      margin-bottom remConvert(20)

     a:not(.button)
       color _gold !important
       border-bottom 1px dotted _gold

       &:hover
         border none



  &__container.result
    width 100%
    color #fff
    text-align left
    margin 0 0 remConvert(40)

    .prizes

      &__wrapper
      &__item
        width 100%

      &__item
        height auto
        min-height auto
        padding-top remConvert(40)
        padding-bottom remConvert(40)

      &__avatar
        border-radius 100%
        overflow hidden

        &_block
          position relative
          display inline-block
          vertical-align top
          padding-right remConvert(50)
          text-align left

      &__position
        position absolute
        top 0
        right remConvert(50)

        width 40px
        height 40px

        font-size 0
        text-align center

        background-color _gold
        border 2px solid _main-gray
        border-radius 100%

        svg
          display inline-block
          width 5px
          height 12px
          margin-top 12px
          margin-bottom 12px
          vertical-align middle

      &__person
        &_block
          display inline-block
          width 70%
          vertical-align top

          .h3
            margin-bottom remConvert(20)

          .celebs
            background-color #6a624f

            &__header
              color _gold

              svg
                display inline-block
                width remConvert(25)
                height remConvert(25)
                vertical-align middle

              span
                display inline-block
                vertical-align middle

            &__items
              text-transform none
              color #9f9e99

              a
                color inherit

                &:hover
                  text-decoration none

              span:first-child
                display inline-block


          small
            display inline-block
            color _gold
            margin-bottom remConvert(20)
