@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.gallery
  text-align left
  align-self center
  margin auto
  font-size 0
  line-height 0
  display flex
  flex-flow row wrap


  &-hidden
  &-close
    display none


  &__item
    position relative
    display inline-block
    width (100 / 3)%
    padding remConvert(10)

    @media (min-width 1280px)
      width (100 / 4)%

    @media (min-width 1650px)
      width (100 / 5)%

      &:nth-of-type(11)
      &:nth-of-type(12)
        display none

    &:after
      // отображается только при наличии аттрибута
      content attr(data-description)
      position absolute
      bottom remConvert(10)
      left remConvert(10)
      right remConvert(10)
      padding-left remConvert(20)
      line-height remConvert(50)
      font-size remConvert(14)
      color _milk
      font-family _condensed
      text-shadow 0 2px 3px rgba(0,0,0,.15)
      background-color _main-gray
      opacity 0
      transition opacity .5s ease

    &:hover
      &:after
        opacity 1

    &-wrapper
      display inline-block
      width 100%
      //height 100%
      box-shadow 0 3px 2px 0 rgba(0,0,0,.25)
      overflow hidden
      line-height 0

    img
      width 100%
      line-height 0
