@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.default-section

  &__wrapper
    display flex
    flex-direction column

  .headline
    align-self center

  /**
    options start
  */

  &.left
    .headline
      align-self flex-start

  &.right
    .headline
      align-self flex-end

  &.light
    background-color #fff
    color _main-gray

    a
      color inherit
      text-decoration none

  &.grey
    color _milk

    .headline__main
      color #fff


  &.dark
    color _milk
    background-color _main-gray

    a:not(.button)
      color _milk
      text-decoration none

    .headline
      &__main
        color #fff

      &__other
      &__text
        color _milk

  /* options end */
