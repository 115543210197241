@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.breadcrumbs
  color #b4b4b7

  a
    color inherit
    text-decoration underline

    &:hover
      color #fff
