@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.reports
  &__wrapper
    display flex
    flex-direction column

    .headline
    .tabs
      align-self center

    .tabs
      margin-bottom 35px

    .gallery
      margin-bottom 50px

    .details
      padding-left remConvert(10)
      align-self center
