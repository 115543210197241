@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.course-tabs-list
  &__item
    padding remConvert(30) 0
    padding-right remConvert(15)
    border-top 1px solid #333439

    &-header
      color #fff
      margin-bottom remConvert(10)

      i
      span
        vertical-align middle
        display inline-block

      i
        width remConvert(26)
        height remConvert(26)
        margin-right remConvert(15)
        color _gold
        text-align center
        fontConvert(14)
        font-style normal
        line-height remConvert(23)
        border 2px solid _gold
        border-radius 100%

    &-about
      color _milk

    &.showAllHelper
      position relative
      //padding 0

      &:after
      &:before
        content ''
        right 0
        position absolute
        width 70%

      &:after
        top 50%
        margin-top -20px
        height 40px
        border-top 1px solid #333439
        border-bottom 1px solid #333439

      &:before
        top 50%
        margin-top -1px
        height 1px
        border-bottom 1px solid #333439

      .course-tabs-list
        &__item
          &-about
            position relative
            z-index 1
            display inline-block
            max-width 30%
            //padding 30px 40px 30px 0
            background-color _main-gray
            color _gold
            border-bottom 1px dotted _gold
            cursor pointer

            &:hover
              border none
              padding-bottom 1px
