@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.B_crumbBox
  padding 0
  list-style none
  color _gold
  font-weight 300

  li
    display inline-block

    a
      color _gold
      font-weight 300

      &:hover
        text-decoration none
