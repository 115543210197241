@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.celebs
  background-color #f8f4eb
  padding remConvert(25) remConvert(20) remConvert(30)
  margin remConvert(10) 0
  font-style italic
  border-radius 6px

  &__header
    fontConvert(22)
    font-weight _regular
    margin-bottom remConvert(10)

  &__items
    color #7d7565

    span
      text-transform uppercase

    span:first-child
      display none
