@import '../../styles/helpers/variables'
@import '../../styles/helpers/mixins'

.sorry
  position fixed
  z-index 100
  top 0
  left 0
  right 0
  text-align center
  padding 0 20px
  background-color _main-gray
  box-shadow 2px 0 2px rgba(0,0,0,.6)
  color _milk
  line-height 44px

  span
    display inline-block
    vertical-align middle

  &__icon
    width 20px
    height 20px
    margin-right 10px
    font-size 16px
    line-height 20px
    border remConvert(2) solid _gold
    font-weight _regular
    color _gold
    border-radius 100%
    text-align center
