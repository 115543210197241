@import "variables.styl"
@import "mixins.styl"

*, *:after, *:before
  box-sizing border-box

html
  font-size 12px

  @media (min-width 1280px)
    font-size 13px

  @media (min-width 1650px)
    font-size (_desktopFontSize)px

body
  min-width _minWidth
  font-family _roboto
  font-weight _regular
  line-height remConvert(30)

.default-block
  min-height 550px
  height 40vw
  width 100%

.block-offset
  padding remConvert(100) 0

.element-offset
  width 80%
  min-width 900px
  align-self center
  margin auto

.h1
  fontConvert(_h1)

.h2
  fontConvert(_h2)

.h3
  fontConvert(_h3)

.h4
  fontConvert(_h4)

.text
  &-center
    text-align center

  &-left
    text-align left

  &-right
    text-align right

  &-italic
    font-style italic

  &-light
    font-weight _light

.container
  width _1024
  min-width 900px
  margin auto

  &.wide
    width 100%

  &--fixed
    width 1000px
    padding 15px

.link
  text-decoration none

  &:hover
    text-decoration underline !important

.bg
  position relative
  background-size cover
  background-position center

  .container
    position relative
    z-index 2

  &:after
    content ''
    position absolute
    left 0
    top 0
    z-index 1
    width 100%
    height 100%
    background-color #000
    opacity .7

.grey
  background-color _main-gray !important
  color #fff !important

  a
    color #b4b4b7
    border-bottom 1px solid #585858
    transition border-color .5s ease
    margin-right 15px
    padding-bottom 1px
    text-decoration none

    &:hover
      border-color #b4b4b7

.w60
  max-width 60%

#big-video-vid_html5_api
  top 0 !important
  left 0 !important
  bottom 0 !important
  right 0 !important